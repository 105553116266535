import { setStorageItem, WebStorageType } from "../../../../common-deprecated/utils/storageUtils";
import type { SelectedFilterLocalStorageType } from "./params";

// ----------------------------------------------------------------------
// Helper types and consts
// ----------------------------------------------------------------------
// This follows the same protocol as defined in the localStorage used in the new cars Build application
const LOCAL_STORAGE_ID = "onlineRetail.modelFilter";

// These fields are optional to catch any implementation bugs at T1 side as well.
type SelectedFiltersLocalStorageItemType = {
    external: {
        selectedFilters: SelectedFilterLocalStorageType;
    };
};

// Type used in the functions for easy param propagation.
type LeasingFilterLocalStorageParams = {
    country: string;
    language: string;
};

// ----------------------------------------------------------------------
// Storage fns
// ----------------------------------------------------------------------

/**
 * Set model filter local storage, only overwriting the values supplied in the storageItem param.
 */
const setModelFilterLocalStorage = (
    params: LeasingFilterLocalStorageParams,
    storageItem: SelectedFiltersLocalStorageItemType,
): void => {
    setStorageItem({ ...params, storageId: LOCAL_STORAGE_ID }, storageItem, WebStorageType.local);
};

export const setSelectedFilters = (
    params: LeasingFilterLocalStorageParams,
    selectedFilters: SelectedFilterLocalStorageType,
): void => {
    setModelFilterLocalStorage(params, { external: { selectedFilters } });
};

export const clearSelectedFilters = (params: LeasingFilterLocalStorageParams): void => {
    setModelFilterLocalStorage(params, { external: { selectedFilters: {} } });
};
