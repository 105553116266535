import styled, { css } from "styled-components";
import type { LayoutProps, WidthProps, SpaceProps, FlexboxProps, DisplayProps } from "styled-system";
import { layout, width, space, flexbox, display } from "styled-system";
import { ButtonWrapper, Button } from "../globals/Button";
import Control from "../globals/Control";
import * as Text from "../utilities/Text";
import type { ColorType } from "../theme/lexusTheme";
import { theme as lexusTheme } from "../theme/lexusTheme";
import { IconWrapper } from "../globals/IconWrapper";
import { getBreakpoint, getDirection } from "../../../../themes/common";
import { Ellipsis } from "../utilities/Ellipsis";

const BUTTON_SIZE_TYPES = ["sm", "lg", "xl"];

type ButtonType = {
    large?: boolean;
    light?: boolean;
    hidden?: boolean;
    selected?: boolean;
    hasIconLeft?: boolean;
    hasIconRight?: boolean;
    fullWidth?: boolean;
    buttonSize?: (typeof BUTTON_SIZE_TYPES)[number];
} & DisplayProps &
    FlexboxProps &
    LayoutProps &
    WidthProps &
    SpaceProps;

export const styleProps = css`
    ${display};
    ${flexbox};
    ${layout};
    ${space};
    ${width};
`;

/**
 * You can also use this component for a link.
 * Then you need to use the `as`-attribute.
 * @Example
 * <Button.Primary as="a"></Button.Primary>
 */

/**
 * @deprecated Deprecated since lexus revamp. Use the ButtonPrimary react component instead.
 */
export const Primary = styled(Button).attrs<ButtonType>((props) => ({
    className: [
        "btn-primary l-micro-text",
        props.disabled ? "btn-disabled" : "",
        props.light ? "white" : "",
        props.buttonSize ? `btn-${props.buttonSize}` : "",
    ].join(" "),
}))<ButtonType>`
    ${styleProps};
`;

/**
 * @deprecated Deprecated since lexus revamp. Use the ButtonPrimary react component instead.
 */
export const Secondary = styled(Button).attrs<ButtonType>((props) => ({
    className: [
        "btn-secondary",
        props.disabled ? "btn-disabled" : "",
        props.light ? " white" : "",
        props.buttonSize ? `btn-${props.buttonSize}` : "",
    ].join(" "),
}))<ButtonType>`
    ${styleProps};
`;

/**
 * Use this component to change the style of buttons on a dark background to a light variant.
 */
export const ButtonLightVariantWrapper = styled.div.attrs(() => ({
    className: "has-dark-styles",
}))``;

export const ButtonPrimary = styled(ButtonWrapper).attrs<ButtonType>(() => ({
    className: "btn-primary",
}))<{ hasIcon?: boolean } & ButtonType>`
    ${styleProps};
    line-height: 2.4rem;

    ${(props) =>
        props.fullWidth &&
        css`
            width: 100%;
            justify-content: center;
        `};

    & > .btn-text {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    span,
    ${Ellipsis} {
        font-family: ${lexusTheme.fonts.bold};
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 4.2rem;
        text-transform: uppercase;
        color: ${lexusTheme.colors.white};
    }

    ${({ hasIcon }) =>
        hasIcon &&
        css`
            ${Text.Button} {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: ${lexusTheme.space[1]}px;
            }
        `};
`;

export const ButtonSecondary = styled(ButtonWrapper).attrs<ButtonType>(() => ({
    className: "btn-secondary",
}))<ButtonType>`
    ${styleProps};
    ${(props) =>
        props.fullWidth &&
        css`
            width: 100%;
            justify-content: center;
        `};
`;

export const ButtonTertiary = styled(ButtonWrapper).attrs<ButtonType>(() => ({
    className: "btn-tertiary",
}))<ButtonType>`
    ${styleProps};
    ${(props) =>
        props.fullWidth &&
        css`
            width: 100%;
            justify-content: center;
        `};
`;

export const ButtonCta = styled(ButtonWrapper).attrs<ButtonType>(() => ({
    className: "btn-secondary",
}))<ButtonType>`
    /* Override default CSS */
    display: flex;
    margin: 0;

    & > ${Text.Button} {
        --borderColor: ${lexusTheme.colors.platinumMedium};
        --backgroundColor: ${lexusTheme.colors.white};
        display: flex;
        column-gap: ${lexusTheme.space[1]}px;
        align-items: center;
        width: 100%;
        padding: ${lexusTheme.space[1] / 2}px ${lexusTheme.space[2]}px ${lexusTheme.space[1] / 2}px ${
            lexusTheme.space[1]
        }px;
        font-family: ${lexusTheme.fonts.book};
        font-size: 1.8rem;
        line-height: 2rem;
        text-align: start;
        text-overflow: clip;
        white-space: normal;
        overflow: visible;

        /* Hide default arrow */
        &::before {
            display: none;
        }
    }

    ${IconWrapper} {
        margin-${getDirection("left")}: auto;
    }

`;

export const ButtonOutlineLight = styled(Button).attrs<ButtonType>((props) => ({
    className: ["cmb-btn", props.large ? "large" : ""].join(" "),
}))<ButtonType>`
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    gap: ${lexusTheme.space[1]}px;
    margin: 0;
    padding: 0 ${lexusTheme.space[1]}px !important;
    border-radius: 4px;
    ${styleProps};

    &,
    &:focus &:hover {
        color: ${lexusTheme.colors.midnight};
        background-color: ${lexusTheme.colors.white} !important;
        border: 1px solid ${lexusTheme.colors.platinumMedium};
    }

    ${Ellipsis} {
        text-align: start;
    }

    /* stylelint-disable-next-line */
    ${IconWrapper}:last-of-type {
        margin-inline-start: auto !important;
    }

    ${(props) =>
        props.fullWidth &&
        css`
            width: 100%;
            justify-content: center;
        `};
`;

export const ButtonLink = styled(ButtonWrapper).attrs<ButtonType>(() => ({
    className: "btn-text-link",
}))<ButtonType>`
    margin-top: ${lexusTheme.space[1] / 2}px;
    padding: 0 !important;
    border-bottom: 1px solid ${lexusTheme.colors.black};
    ${styleProps};

    .btn-text:hover {
        &::after {
            display: none !important;
        }
    }
`;

export const ButtonWithIcon = styled(ButtonWrapper).attrs<ButtonType>(() => ({
    className: "btn-text-link",
}))<ButtonType>`
    ${styleProps};
    ${(props) =>
        props.fullWidth &&
        css`
            width: 100%;
            justify-content: center;
        `};
    ${Text.Button} {
        max-width: 100%;
        /* Fix RTL issue from Zaidan styles. (OR-7172) */
        &.icon-left {
            .l-icon::before {
                ${({ theme }) =>
                    theme.isRTL &&
                    css`
                        transform: scaleX(1) !important;
                    `};
            }
        }
        /* Fix strange hover effect from parent CSS */
        &:hover {
            &::after {
                display: none !important;
            }
        }
    }
    ${Text.IconButton} {
        text-overflow: ellipsis;
        overflow-x: hidden;
    }
`;

/**
 * @deprecated Deprecated since lexus revamp. Use the ButtonPrimary react component instead.
 */
export const Tertiary = styled(Button).attrs<ButtonType>((props) => ({
    className: [
        "btn-tertiary",
        props.disabled ? "btn-disabled" : "",
        props.light ? " white" : "",
        props.buttonSize ? `btn-${props.buttonSize}` : "",
    ].join(" "),
}))<ButtonType>`
    ${styleProps};
`;

/**
 * @deprecated Deprecated since lexus revamp. Use the ButtonPrimary react component instead.
 */
export const Gradient = styled(Button).attrs<ButtonType>(() => ({
    className: "btn-gradient-outline",
}))<ButtonType>`
    ${styleProps};
    ${Text.Button} {
        font-size: 1.4rem;
        line-height: 1.8rem;
        text-transform: uppercase;
    }
`;

export const ButtonIcon = styled(Button).attrs(() => ({
    className: "btn-icon-monochrome",
}))<ButtonType>`
    ${styleProps};
`;

/**
 * Specific button variations
 */
export const ViewMore = styled(Button).attrs<ButtonType>(() => ({
    className: "view-more",
}))<ButtonType>`
    font-family: ${lexusTheme.fonts.bold};
    font-size: 1.4rem;
    line-height: 2rem;
    text-align: start;
    text-transform: uppercase;
    color: ${lexusTheme.colors.azure};
    ${space};

    /* Fix position icon */
    .icon {
        position: relative;
        top: 0;
    }
`;

/**
 * Button Icon
 * @Example
 * <Button.Icon large><IconRemove /></Button.Icon>
 */
export const Icon = styled(Control).attrs<ButtonType>((props) => ({
    className: ["control-reset", props.large ? "large" : ""].join(" "),
}))<ButtonType>`
    ${styleProps};
    ${(props) =>
        props.hidden &&
        `
            visibility: hidden
        `}
    ${(props) =>
        props.light &&
        `
        color: ${lexusTheme.colors.lightest} !important;
    `};

    .icon,
    .dxp-icon {
        &::before {
            font-size: 2rem !important; /* Override TME styles */
        }
    }
`;

/**
 * Button Icon Outline
 * @example
 * <Button.IconOutline><IconRemove /></Button.IconOutline>
 */
export const IconOutline = styled(Control).attrs<ButtonType>((props) => ({
    className: ["cmp-btn", "btn-tertiary", props.large ? "large" : ""].join(" "),
}))<ButtonType>`
    ${styleProps};
    ${(props) =>
        props.hidden &&
        `
            visibility: hidden
        `}
    ${(props) =>
        props.light &&
        `
        color: ${lexusTheme.colors.lightest} !important;
    `};
    width: 48px;
    min-width: auto;
    height: 48px;
    color: var(--color);
    border-color: var(--backgroundColor);
    border-radius: 50%;
    border-style: solid;
    border-width: 1px;

    & > .btn-text {
        padding: 0 1.2rem !important;
    }
`;

/**
 * Custom button styles I couldn't find in the CSS file
 */

// TO DO: Fix custom border-bottom / hover animation
// Maybe the design team should deliver class names for this kind of button?
export const Outline = styled(Button).attrs<ButtonType>((props) => ({
    className: ["btn-primary white", props.disabled ? "btn-disabled" : ""].join(" "),
}))<ButtonType>`
    border: 1px solid ${lexusTheme.colors.black};
    &:hover {
        border-color: ${lexusTheme.colors.grey4};
    }
    ${styleProps};
    ${Text.Button} {
        color: ${lexusTheme.colors.black} !important;
    }
`;

export const PrimaryWhite = styled(Primary).attrs<ButtonType>(() => ({
    className: "white",
}))<ButtonType>`
    display: flex;
    background-color: ${lexusTheme.colors.lightest} !important;
    ${styleProps};
    ${Text.Button} {
        color: ${lexusTheme.colors.black} !important;
    }
`;

export const OutlineWhite = styled(Button).attrs<ButtonType>((props) => ({
    className: ["btn-primary white", props.disabled ? "btn-disabled" : ""].join(" "),
}))<ButtonType>`
    border: 1px solid ${lexusTheme.colors.lightest};
    ${styleProps};
    ${Text.Button} {
        color: ${lexusTheme.colors.lightest} !important;
    }
`;

export const PrimaryGrey = styled(Primary).attrs<ButtonType>(() => ({
    className: "white",
}))<ButtonType>`
    display: flex;
    align-items: center;
    background-color: ${lexusTheme.colors.grey1} !important;
    ${styleProps};
    ${Text.Button} {
        color: ${lexusTheme.colors.black} !important; /* Override CSS */
    }
    ${IconWrapper} {
        margin-inline-end: ${lexusTheme.space[1]}px;
        .icon::before {
            font-size: 1.8rem;
        }
    }
`;

export const GreySquare = styled(Button).attrs<ButtonType>(() => ({}))<{ hasOnlyIcon?: boolean } & ButtonType>`
    display: flex;
    align-items: center;
    min-width: 32px;
    height: 32px;
    margin: 0;
    padding: ${lexusTheme.space[1] / 2}px ${lexusTheme.space[1]}px;
    font-family: ${lexusTheme.fonts.regular};
    font-size: 1.3rem;
    line-height: 2rem;
    color: ${lexusTheme.colors.dark};
    background-color: ${lexusTheme.colors.grey1};
    &:hover {
        background-color: ${lexusTheme.colors.grey2};
    }
    ${({ hasOnlyIcon }) =>
        hasOnlyIcon &&
        css`
            justify-content: center;
            width: 32px;
            padding: ${lexusTheme.space[1] / 2}px 0;
            text-align: center;
        `};
    ${styleProps};
`;

export const Cta = styled(Button).attrs<ButtonType>((props) => ({
    className: ["btn-secondary", props.disabled ? "btn-disabled" : ""].join(" "),
}))<ButtonType>`
    display: flex;
    padding-inline-start: 0 !important;
    border: 0 !important;
    ${IconWrapper} {
        align-self: flex-start;
        margin-inline-end: ${lexusTheme.space[2]}px;
        .icon {
            width: 33px;
            height: 33px;
            line-height: 3.3rem;
            background-color: ${lexusTheme.colors.grey1};
        }
    }
`;

/**
 * Checkbox is a button variant that looks like a checkbox.
 * It replaces the old colourwheel (rainbow) button.
 */
export const Checkbox = styled.button<ButtonType>`
    display: flex;
    align-items: center;
    background-color: ${lexusTheme.colors.white};

    ${IconWrapper} {
        display: flex;
        align-items: center;
        align-self: flex-end;
        justify-content: center;
        width: 32px;
        min-width: 32px;
        height: 32px;
        color: ${lexusTheme.colors.azure};
        background-color: ${lexusTheme.colors.white};
        border: 1px solid ${lexusTheme.colors.azure};
        border-radius: 4px;
        transition: background-color 0.3s;

        .dxp-icon::before {
            color: ${lexusTheme.colors.azure};
        }

        &::before {
            position: relative;
            top: 5px;
        }
        ${(props) =>
            props.selected &&
            css`
                color: ${lexusTheme.colors.white};
                background-color: ${lexusTheme.colors.azure};

                .dxp-icon::before {
                    color: ${lexusTheme.colors.white};
                }
            `};
        ${({ disabled, selected }) =>
            disabled &&
            selected &&
            css`
                color: ${lexusTheme.colors.white};
                background-color: ${lexusTheme.colors.platinumMedium};
                border-color: ${lexusTheme.colors.platinumMedium};
                cursor: default;

                .dxp-icon::before {
                    color: ${lexusTheme.colors.white};
                }
            `};
        ${({ disabled, selected }) =>
            disabled &&
            !selected &&
            css`
                opacity: 0.2;
                cursor: default;
            `};
    }
    ${Text.Button} {
        margin-inline-end: ${lexusTheme.space[2]}px;
        font-size: 1.8rem;
        line-height: 3rem;
        color: ${lexusTheme.colors.platinumMedium};
        ${(props) =>
            props.disabled &&
            css`
                color: ${lexusTheme.colors.titanium};
            `};
    }
`;

/* BUTTON ICON STYLES */

const linkHasIconStyles = css`
    display: inline-flex;
    align-items: center;
    ${IconWrapper} {
        margin-inline-end: ${lexusTheme.space[1]}px;
    }
`;

const linkHasIconRightStyles = css`
    display: inline-flex;
    align-items: center;
    ${IconWrapper} {
        margin-inline-start: ${lexusTheme.space[1]}px;
        margin-inline-end: 0;
    }
`;

type LinkType = {
    hasIcon?: boolean;
    hasIconRight?: boolean;
    small?: boolean;
    color?: ColorType;
    noUppercase?: boolean;
    fullWidth?: boolean;
} & ButtonType;

/**
 * Button.Link defaults to small buttons. Add the `large` prop for having a larger button.
 */

export const Link = styled.button<LinkType>`
    display: inline-block;
    margin: 0;
    font-family: ${lexusTheme.fonts.book};
    font-size: 1.4rem;
    letter-spacing: 0.1rem;
    line-height: 1.9rem;
    text-transform: uppercase;
    color: ${(props) => (props.color ? lexusTheme.colors[props.color] : lexusTheme.colors.dark)};
    border: none;
    ${(props) => props.hasIcon && linkHasIconStyles};
    ${(props) => props.hasIconRight && linkHasIconRightStyles};
    ${styleProps};
    ${(props) =>
        props.large &&
        css`
            font-size: 1.8rem;
            line-height: 2.4rem;
        `};
    ${(props) =>
        props.small &&
        css`
            font-size: 1.4rem;
            line-height: 1.8rem;
        `};
    ${(props) =>
        props.noUppercase &&
        css`
            text-transform: none;
        `};
    ${(props) =>
        props.fullWidth &&
        css`
            display: flex;
            justify-content: space-between;
            width: 100%;
        `};
`;

export const EllipsisText = styled.span<DisplayProps>`
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    ${display}
`;

export const Clamp = styled.span`
    display: block;
    min-width: 75px;
    max-height: 34px;
    line-height: 1;
    white-space: normal;
    @media ${getBreakpoint("up", "lg")} {
        display: block;
        width: 100%;
        min-width: auto;
        overflow: hidden;
        /* stylelint-disable */
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        /* stylelint-enable */
    }
`;

/**
 * Custom Button variants
 */

export const ButtonQuaternary = styled(Button)<{ dark?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    min-width: auto;
    margin: 0;
    padding: ${lexusTheme.space[1]}px;
    font-family: ${lexusTheme.fonts.book};
    font-size: 1.3rem;
    letter-spacing: 0.01rem;
    line-height: 1.7rem;
    color: ${lexusTheme.colors.midnight};
    background-color: ${({ dark }) => (dark ? lexusTheme.colors.steel : lexusTheme.colors.silver)};
    &:hover,
    &:focus {
        background-color: ${({ dark }) => (dark ? lexusTheme.colors.platinumDark : lexusTheme.colors.platinumLight)};
    }
    &:disabled {
        color: ${lexusTheme.colors.brushedSteel};
        background-color: ${lexusTheme.colors.mist};
    }
    /* Override default styles from .btn-text */
    ${Text.Button} {
        padding: 0 ${lexusTheme.space[1]}px;
        font-family: ${lexusTheme.fonts.book};
        font-size: 1.3rem;
        letter-spacing: 0.01rem;
        line-height: 1.7rem;
    }
    ${Text.Button},
    ${IconWrapper} {
        ${({ dark }) =>
            dark &&
            css`
                color: ${lexusTheme.colors.white};
            `};
    }
`;
