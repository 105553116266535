import React from "react";
import * as Styles from "./style/ModelResultPriceStyles";
import { useModelFilterSelector } from "../../../../redux/store";
import type { PriceType } from "../../../../../../../common-deprecated/types/CommonTypes";
import {
    formatAEMCashPrice,
    getAemFlexibilityMatrixConfig,
    getAemPriceConfig,
    hasDualCurrency,
} from "../../../../../../../common-deprecated/settings/utils/financeSettingUtilsAem";
import { AemFmComponent } from "../../../../../../../common-deprecated/utils/aemFlexibilityMatrixUtils";
import { ModelFilterLabel } from "../../../../utils/labels";
import { formatPrice } from "../../../../../../../common-deprecated/Globalize";

export type ModelResultCashPriceType = {
    price: PriceType;
    modelCode?: string;
};
const ModelResultCashPrice = (props: ModelResultCashPriceType): JSX.Element | null => {
    const { price, modelCode = "" } = props;
    const commonSettings = useModelFilterSelector((state) => state.commonSettings);

    const hasSecondaryPrice = hasDualCurrency(commonSettings);
    const fmConfig = getAemFlexibilityMatrixConfig(commonSettings, AemFmComponent.ModelFilter, "new", modelCode);
    const priceConfig = getAemPriceConfig(commonSettings, AemFmComponent.ModelFilter, modelCode);

    if (!priceConfig.priceEnabled) return null;

    const formattedPrimaryDiscount = formatAEMCashPrice(
        price.discount,
        commonSettings.culture.name,
        priceConfig,
        price.licenseFee,
    );
    const formattedSecondaryDiscount = formatAEMCashPrice(
        price.discount,
        commonSettings.culture.name,
        priceConfig,
        price.licenseFee,
        true,
    );

    return (
        <Styles.PricesWrapper>
            {
                <>
                    <Styles.Cash>
                        {/* The label priceStart is used when there is no flexibility matrix configured */}
                        {fmConfig ? (
                            <span dangerouslySetInnerHTML={{ __html: formattedPrimaryDiscount }} />
                        ) : (
                            <ModelFilterLabel
                                label="priceStart"
                                params={{ value: formatPrice(price.discount, commonSettings.culture.name) }}
                            />
                        )}
                    </Styles.Cash>
                    {/* Dual currency price */}
                    {hasSecondaryPrice && (
                        <Styles.Cash>
                            {fmConfig ? (
                                <span dangerouslySetInnerHTML={{ __html: formattedSecondaryDiscount }} />
                            ) : (
                                <ModelFilterLabel
                                    label="priceStart"
                                    params={{
                                        value: formatPrice(price.discount, commonSettings.culture.name, false, true),
                                    }}
                                />
                            )}
                        </Styles.Cash>
                    )}
                </>
            }
        </Styles.PricesWrapper>
    );
};

export default ModelResultCashPrice;
