import styled from "styled-components";
import { theme } from "../../../../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";
import { getBreakpoint, getDirection } from "../../../../../../../../common-deprecated/themes/common";

export const PricesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Cash = styled.div.attrs(() => ({
    className: "l-micro-text",
}))`
    margin-bottom: 0;
    font-family: ${theme.fonts.book};
    font-size: 1.6rem;
    letter-spacing: 0.1rem;
    line-height: 2rem;
    text-transform: uppercase;
    color: ${theme.colors.steel};

    span {
        display: block;
        font-family: Arial, sans-serif;
        font-size: 1.6rem;
        line-height: 2rem;
        color: ${theme.colors.azure};

        @media ${getBreakpoint("up", "xl")} {
            font-size: 1.8rem;
        }
    }

    > span:not(:only-child):first-child {
        color: ${theme.colors.brushedSteel};
    }

    > span:not(:only-child):nth-last-child(2) {
        font-size: 1.4rem;
        color: ${theme.colors.brushedSteel};
    }

    > span:not(:only-child):nth-last-child(1) {
        display: flex;
        align-items: center;
        font-size: 1.6rem;
        line-height: 2.4rem;

        @media ${getBreakpoint("up", "xl")} {
            font-size: 1.8rem;
        }
    }
`;

export const SpanText = styled.div`
    white-space: nowrap;
`;

export const CashDiscount = styled(Cash)`
    text-decoration: line-through;
`;

export const Monthly = styled.div.attrs(() => ({
    className: "t-nano-text",
}))`
    display: flex;
    align-items: center;
    margin-top: ${theme.space[1] / 2}px;
    font-family: ${theme.fonts.book};
    font-size: 1.4rem;
    line-height: 2rem;
    text-transform: uppercase;
    color: ${theme.colors.steel};
    span {
        white-space: nowrap;
    }
`;

export const MonthlyPriceDisclaimer = styled.button`
    display: flex;
    align-items: center;
    padding: 0;
    background: transparent;
    border: none;
`;

export const DisclaimerIcon = styled.span`
    display: flex;
    align-items: center;
    color: ${theme.colors.steel};
`;

export const MonthlyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const LegalText = styled.p.attrs(() => ({
    className: "t-nano-text",
}))`
    margin: 4px 0 0;
    text-align: ${getDirection("left")};
    text-indent: 0;
    color: ${theme.colors.steel};
`;
