import type { LabelComponentType } from "../../../../common-deprecated/components/label/Label";
import { getLabelComponent } from "../../../../common-deprecated/components/label/Label";
import type { GetLabelType, UseLabelType, UseOptionalLabelType } from "../../../../common-deprecated/hooks/useLabel";
import { getUseLabel, getUseOptionalLabel, getLabelFn } from "../../../../common-deprecated/hooks/useLabel";
import type { CommonSettingsType } from "../../../../common-deprecated/settings/fetchCommonSettings";
import type { ModelFilterSettingsType } from "../redux/reducers/ModelFilterSettingsReducer";
import type { ModelFilterStateType } from "../redux/store";
import { ModelFilterSortOrder } from "./constants/filterConstants";

// ----------------------------------------------------------------------
// Accessibility labels
// ----------------------------------------------------------------------
export const accessibilityLabels = {
    ariaAddFilter: "Add filter",
    ariaRemoveFilter: "Remove filter",
    ariaFilter: "Filter",
    ariaFilterResults: "Number of filtered results",
};

export const modelFilterLabels = {
    ...accessibilityLabels,

    // ----------------------------------------------------------------------
    // Model Filter labels
    // ----------------------------------------------------------------------
    category: "Category",
    fuel: "Fuel",
    priceCash: "Cash",
    priceMonthly: "Monthly",
    back: "Back",
    clearFilter: "Clear {value} filter",
    clearFilters: "Clear {value} filters",
    seeResult: "See {value} result",
    seeResults: "See {value} results",
    allFilters: "All filters",
    seeVariants: "See {value} variants", // Deprecated in favour of goToBuild since OR-8107
    goToBuild: "Configure",
    priceStart: "From {value}",
    noResults: "No results",
    noResultsTitle: "Oops, no results",
    noResultsDescription: "Try to remove some of the filters",
    clearAllFilters: "Remove all",
    modelsCount: "{value} models found",
    resultCount: "({value} variants)",
    activeFilterCount: "{value} active filters",
    typePlus: "Type",
    pricePlus: "Price",
    fuelPlus: "Fuel",
    showAllFilters: "Show all filters",
    pillValueSeparator: ", ",
    pillFinalValueSeparator: " and ",

    // Sorting
    sortModelIndex: "Most popular",
    sortModelName: "Model name",
    sortCashAsc: "Cash Price - Lowest",
    sortCashDesc: "Cash Price - Highest",
    sortMonthlyAsc: "Monthly Price - Lowest",
    sortMonthlyDesc: "Monthly Price - Highest",

    // Filter titles (optional)
    // Deprecated - Now using titleLabel directly from filterConfig in component-settings
    filterLabelEta: "Eta",
    filterLabelDoors: "Doors",
    filterLabelSeats: "Seats",
    filterLabelPowerOutput: "Power output",
    filterLabelco2: "CO2",
    filterLabelFuelConsumption: "Fuel consumption",
    filterLabelLuggage: "Luggage",
    filterLabelSpeed: "Speed",
    filterLabelPrice: "Price",
    filterLabelEquipment: "Equipment",
    filterLabelCarType: "Type of car",
    filterLabelOnlineSales: "Buy Online",

    filterFuelTypePlugin: "Plug-in",
    comingSoonExplore: "Explore",
    comingSoonSeeVariants: "See All the variants",
    viewModel: "View model",

    // retailer variant specific labels
    retailerTitle: "New cars",
    retailerChooseModel: "Choose your model",

    // B2B
    b2bAvailable: "Also available for <b>business customers</b> with KINTO One",
};

export const sortOrderLabelMap: Record<ModelFilterSortOrder, ModelFilterLabelType> = {
    [ModelFilterSortOrder.ModelIndex]: "sortModelIndex",
    [ModelFilterSortOrder.ModelName]: "sortModelName",
    [ModelFilterSortOrder.CashAsc]: "sortCashAsc",
    [ModelFilterSortOrder.CashDesc]: "sortCashDesc",
    [ModelFilterSortOrder.MonthlyDesc]: "sortMonthlyDesc",
    [ModelFilterSortOrder.MonthlyAsc]: "sortMonthlyAsc",
};
// ----------------------------------------------------------------------
// Helpers
// ----------------------------------------------------------------------

export type ModelFilterLabelType = keyof typeof modelFilterLabels;
type ModelFilterLabelParamType = Partial<Record<"value", string | number>>;
type ModelFilterLabelStateType = { commonSettings: CommonSettingsType; modelFilterSettings: ModelFilterSettingsType };

export const getModelFilterLabel = getLabelFn(
    (state: ModelFilterStateType) => state.modelFilterSettings.labels,
    (state: ModelFilterStateType) => !!state.commonSettings.query.showDictionaryKeys,
) as GetLabelType<ModelFilterLabelStateType, ModelFilterLabelType, ModelFilterLabelParamType>;

export const useModelFilterLabel = getUseLabel(
    (state: ModelFilterStateType) => state.modelFilterSettings.labels,
    (state: ModelFilterStateType) => !!state.commonSettings.query.showDictionaryKeys,
) as UseLabelType<ModelFilterLabelType, ModelFilterLabelParamType>;

export const useModelFilterOptionalLabel = getUseOptionalLabel(
    (state: ModelFilterStateType) => state.modelFilterSettings.labels,
    (state: ModelFilterStateType) => !!state.commonSettings.query.showDictionaryKeys,
) as UseOptionalLabelType<ModelFilterLabelType, ModelFilterLabelParamType>;

export const ModelFilterLabel = getLabelComponent(useModelFilterLabel) as LabelComponentType<
    ModelFilterLabelType,
    ModelFilterLabelParamType
>;
