import Debug from "../../../../common-deprecated/Debug";
import type { SectionType } from "../../../../common-deprecated/utils";
import {
    isValidGuid,
    retailerAppsQueryParams,
    SECTION_CUSTOMIZE,
    SECTION_DEFAULT,
    SECTION_ENGINE_GRADE,
    SECTION_SERVICES,
    SECTION_SUMMARY,
} from "../../../../common-deprecated/utils";
import type { QueryType } from "../../../../common-deprecated/server/types";
import type { CommonSettingsType } from "../../../../common-deprecated/settings/fetchCommonSettings";

export enum NavStep {
    Model = "model",
    Submodels = "submodels",
    ModelEngine = "model-engine",
    Grades = "grades",
    Engine = "engine",
    Customize = "customize",
    Services = "services",
    Summary = "summary",
    Buy = "buy",
    Reserve = "reserve",
    Lease = "lease",
}

// Extend with other possibilities if necessary
export enum NavOrigin {
    Unknown = "",
    FinancialCta = "origin-finance-cta",
    InsuranceCta = "origin-insurance-cta",
}

export const navStepsOrder = [
    NavStep.Model,
    NavStep.Engine,
    NavStep.Customize,
    NavStep.Services,
    NavStep.Summary,
    NavStep.Buy,
    NavStep.Reserve,
    NavStep.Lease,
];

export const lexusAemNavStepsOrder = [
    NavStep.Submodels,
    NavStep.Grades,
    NavStep.Customize,
    NavStep.Services,
    NavStep.Summary,
    NavStep.Buy,
];

// ⚠️ For usage in newer components, use `commonQueryParams` from the common utils.ts file instead
// Querystrings that need propagation. Don't add "filterIndex" or "se" (=selectedExtras) here, it needs special parsing.
export const PROPAGATED_QUERY_TYPES: (keyof QueryType)[] = [
    "dealer",
    "saveDealerId",
    "hideprices",
    "disableTokens",
    "modelToken",
    "promoToken",
    "quoteId",
    "showTradeIn",
    "selectedEquipment",
    "enableWLTPPriceCalculation",
    "modelGeneration",
    "useMockData",
    "disableErrorModal",
    "carConfigVersion",
    "showDictionaryKeys",
    "pro",
    "enableBuildSPA",
    "wcmmode" as any, // necessary for step navigation to work in AEM author "view as published" mode
    "variantBrand",
    "addServiceProductToSave",
    "enablePriceServiceCalculation",
    "isStandalone",
    "showFinanceInsuranceInPage",
    "showMergedModelStep",
    "financeProductType",
];

// Path, step and model props should always be there, validateNavigation will take care of cases where it is not provided.
export type NavigationType = {
    path: string;
    step: NavStep;
    model: string;
    car?: string;
    submodel?: string;
    bodyType?: string;
    grade?: string;
    filterIndex?: number;
};

export type NavigationOptionType = {
    modelId: string;
    subModelId?: string;
    bodyTypeId: string;
    gradeId: string;
    carId: string;
    filterIndex?: string;
    selectedEquipment?: string;
    isB2B?: boolean;
};

// WARNING: Be careful with importing Build and buy files here, this class is used outside the build folder.
// Importing other files could significantly increase the bundle size of other parts of the project.
export default class Navigation {
    /**
     * Build a build and buy querystring to be added to a main url.
     */
    static build(
        settings: CommonSettingsType,
        step: NavStep,
        navOptions: NavigationOptionType,
        customQueryString?: string,
        disabledQueryStrings?: string[],
    ): string {
        const { modelId, bodyTypeId, gradeId, carId, subModelId } = navOptions;

        // Use new filterIndex value if we get one, fallback to current one.
        // Use isNaN check because filterIndex 0 is possible.
        const filterIndex =
            !Number.isNaN(navOptions.filterIndex as any) && typeof navOptions.filterIndex !== "undefined"
                ? navOptions.filterIndex
                : settings.query.filterIndex;

        // Only add the selectedEquipment if it is not already part of the query string
        // Be sure that if it's defined it doesn't get added (when for instance generating grade cards in the proace step which do not have selectedEquipment defined).
        const query =
            typeof navOptions.selectedEquipment !== "undefined" && step !== NavStep.Model
                ? navOptions.selectedEquipment !== ""
                    ? { ...settings.query, selectedEquipment: navOptions.selectedEquipment }
                    : settings.query
                : settings.query;

        // Build additional querystrings.
        const queryKeys = [...PROPAGATED_QUERY_TYPES, ...retailerAppsQueryParams].filter(
            (key) => !disabledQueryStrings?.includes(key),
        );

        const queryStrings = (Object.keys(query) as (keyof QueryType)[])
            .filter((queryKey) => queryKeys.includes(queryKey))
            // @ts-ignore
            .map((queryKey) => (query[queryKey] !== undefined ? `${queryKey}=${query[queryKey].toString()}` : ""))
            .concat(
                // @ts-ignore
                !Number.isNaN(filterIndex) && typeof filterIndex !== "undefined" ? [`filterIndex=${filterIndex}`] : [],
            )
            .concat(customQueryString ? [customQueryString] : [])
            .sort()
            .join("&");

        switch (step) {
            case NavStep.Model: {
                const path = `?path=${step}/${modelId}${queryStrings ? `&${queryStrings}` : ""}`;
                return path || "";
            }
            case NavStep.Submodels: {
                return `?path=${step}/${modelId}${queryStrings ? `&${queryStrings}` : ""}`;
            }
            case NavStep.Grades:
                return `?path=${step}/${modelId}/${subModelId || ""}/${bodyTypeId}${
                    queryStrings ? `&${queryStrings}` : ""
                }`;
            case NavStep.Engine:
                return `?path=${step}/${modelId}/${bodyTypeId}/${gradeId}${queryStrings ? `&${queryStrings}` : ""}`;
            case NavStep.Customize:
            case NavStep.Services:
            case NavStep.Summary:
            case NavStep.Buy: {
                return `?path=${step}/${modelId}/${carId}${queryStrings ? `&${queryStrings}` : ""}`;
            }
            default:
                Debug.warn("Invalid step", step);
                return "";
        }
    }

    /**
     * Parses the path querystring into the correct ids.
     */
    static parsePath(query: { [index: string]: any }): NavigationType | false {
        const { path, filterIndex } = query;
        if (!path) return false;

        const [step, ...params] = path.split("/");

        switch (step) {
            case NavStep.Model:
            case NavStep.Submodels: {
                const [model] = params;
                return { path, step, model, filterIndex };
            }
            case NavStep.Grades: {
                const [model, submodel, bodyType] = params;
                return { path, step, model, submodel, bodyType, filterIndex };
            }
            case NavStep.Engine: {
                const [model, bodyType, grade] = params;
                return { path, step, model, bodyType, grade, filterIndex };
            }
            case NavStep.Customize:
            case NavStep.Services:
            case NavStep.Summary:
            case NavStep.Buy: {
                const [model, car] = params;
                return { path, step, model, car, filterIndex };
            }
            default:
                return false;
        }
    }

    /**
     * Check if a navigation object contains errors. Returns an error string when an error is found.
     */
    static validateNavigation(navigation: NavigationType): { valid: boolean; errors: string[] } {
        const { step, model, bodyType, grade, submodel, filterIndex, car } = navigation;
        const errors = [];
        if (!step) errors.push("No step defined");
        else if (!Object.values(NavStep).includes(step)) errors.push(`Invalid step defined: ${step}`);
        // We're not checking for model GUID validity because "yaris" is also possible.
        // The fallback to a modelId when a model name is given is handled in fetch logic.
        if (!model || model === "undefined") errors.push("No model defined");
        if (filterIndex && isNaN(filterIndex)) errors.push("FilterIndex is not a number"); //eslint-disable-line no-restricted-globals

        switch (step) {
            case NavStep.Grades:
                if (!isValidGuid(submodel)) errors.push(`Incorrect bodyType GUID defined ${submodel || ""}`);
                break;
            case NavStep.Engine:
                if (!isValidGuid(bodyType)) errors.push(`Incorrect bodyType GUID defined ${bodyType || ""}`);
                if (!isValidGuid(grade)) errors.push(`Incorrect grade GUID defined ${grade || ""}`);
                break;
            case NavStep.Services:
            case NavStep.Customize:
            case NavStep.Summary:
                if (!isValidGuid(car)) errors.push(`Incorrect car GUID defined ${car || ""}`);
                break;
            case NavStep.Buy:
                errors.push("Buy step is not supported as a deeplink, use summary instead");
                break;
        }

        return { valid: errors.length === 0, errors };
    }

    static getSectionFromStep(step: NavStep | null): SectionType {
        switch (step) {
            case NavStep.Model:
            case NavStep.Engine:
                return SECTION_ENGINE_GRADE;
            case NavStep.Customize:
                return SECTION_CUSTOMIZE;
            case NavStep.Services:
                return SECTION_SERVICES;
            case NavStep.Summary:
            case NavStep.Buy:
                return SECTION_SUMMARY;
            default:
                return SECTION_DEFAULT;
        }
    }

    static getStepOrderIndex(step: NavStep, isLexus: boolean): number {
        if (isLexus) return lexusAemNavStepsOrder.indexOf(step);
        return navStepsOrder.indexOf(step);
    }

    static isCustomizeOnwards(step: NavStep, isLexus: boolean): boolean {
        return Navigation.getStepOrderIndex(step, isLexus) >= Navigation.getStepOrderIndex(NavStep.Customize, isLexus);
    }
}
