import styled from "styled-components";

export const AvailabilityBadges = styled.div.attrs(() => ({
    className: "availability-badges",
}))``;

export const List = styled.ul.attrs(() => ({
    className: "list-unstyled",
}))`
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;
`;
