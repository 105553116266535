import type { CommonSettingsType } from "../../../settings/fetchCommonSettings";
import { searchModelMapValue } from "../../../settings/utils/commonSettingUtils";
import { propagateCommonQueryParamsAsString } from "../../../utils";

export enum CarConfigStep {
    Highlights = "highlights",
    Configure = "configure",
    Specs = "specs",
}

export const getCarConfigStepPath = (carConfigStep: CarConfigStep, modelId: string, carId: string): string => {
    return `${carConfigStep}/${modelId}/${carId}`;
};

export const getCarConfigUrl = (
    commonSettings: CommonSettingsType,
    modelId: string,
    modelCode: string,
    carId: string,
    carConfigStep: CarConfigStep,
    colour?: { colourId: string; colourCode: string },
    upholstery?: { upholsteryId: string; upholsteryCode: string },
    wheelId?: string,
    extras?: string[],
): string => {
    const { country, language } = commonSettings;

    const queryParam = propagateCommonQueryParamsAsString(commonSettings, {
        path: getCarConfigStepPath(carConfigStep, modelId, carId),
        c: colour?.colourId,
        u: upholstery?.upholsteryId,
        w: wheelId,
        useLbxJourney: commonSettings.query.useLbxJourney,
        // not propagating ccSection for retailer could result in page refreshes when navigating sections (e.g. via a ConfigureNavigationButton). Did not further investigate why this fixes the issue.
        // See https://github.com/marlon-be/toyota-online-retailer/pull/8271#discussion_r1914709864
        ccSection: commonSettings.query.ccSection,
        e: extras,
    });

    if (commonSettings.isStandalone) {
        return `/${country}/${language}/car-config?${queryParam}`;
    }

    const baseUrl = searchModelMapValue<CommonSettingsType>(commonSettings, modelId)?.links?.buildPage;

    if (!baseUrl) {
        return "";
    }

    // If only colour is present, AEM selectors can be used in stead.
    if (colour && !upholstery && !wheelId) {
        // !!! The AEM selectors will not work if there is a .html extension before it
        // FE: toyota-c-hr/build.html.c-hr.2yt.7d85c5f3-5b8d-4995-9ec4-d22fd7ff1f5f
        // Th right syntax is toyota-c-hr/build.c-hr.2yt.7d85c5f3-5b8d-4995-9ec4-d22fd7ff1f5f.html

        // In production the URLs don't have the .html extension so this does not need to happen there.
        const baseUrlHasHtmlTag = baseUrl.endsWith(".html");

        // colourCode needs to be lowercase in the AEM selectors otherwise this will not work.
        // We check if the baseURL has an html extension here and if it does we get the last 5 characters from the url as this will be the HTML tag.
        // This HTML tag will be placed at the end as this is also needed
        return `${baseUrlHasHtmlTag ? baseUrl.substring(0, baseUrl.length - 5) : baseUrl}.${modelCode as string}.${
            colour.colourCode.toLowerCase() as string
        }.${carId as string}${baseUrlHasHtmlTag ? baseUrl.substring(baseUrl.length - 5) : ""}`;
    }

    const baseUrlParts = baseUrl.split("/");
    baseUrlParts[baseUrlParts.length - 2] = `${baseUrlParts[baseUrlParts.length - 2]}`;

    return `${baseUrlParts.join("/")}?${queryParam}`;
};

export const getStandaloneGradeSelectorUrl = (modelId: string, commonSettings: CommonSettingsType): string => {
    const queryParam = propagateCommonQueryParamsAsString(commonSettings, {
        modelId,
    });
    return `/${commonSettings.country}/${commonSettings.language}/grade-selector?${queryParam}`;
};
