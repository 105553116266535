import styled, { css } from "styled-components";
import type { SpaceProps, PositionProps, WidthProps } from "styled-system";
import { space, position, width } from "styled-system";
import type { ColorType, IconSizeType } from "../theme/lexusTheme";
import { theme } from "../theme/lexusTheme";

export const IconWrapper = styled.span<{ iconColor?: ColorType; size?: IconSizeType } & SpaceProps & WidthProps>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    ${space};
    ${width};

    ${({ iconColor }) =>
        iconColor &&
        css`
            color: ${theme.colors[iconColor]} !important;
        `};

    .icon,
    .dxp-icon {
        padding: 0;
        ${({ size }) =>
            size &&
            css`
                line-height: 1;
                &::before {
                    font-size: ${theme.iconSize[size]} !important;
                }
            `}
    }

    img {
        max-width: 100%;
    }
`;

export const ToggleIconWrapper = styled.span<
    { iconColor?: ColorType; isDown: boolean; size?: IconSizeType } & SpaceProps & PositionProps
>`
    display: inline-flex;
    position: relative;
    align-items: center;
    width: 20px;
    height: 20px;
    ${space};

    ${({ iconColor }) =>
        iconColor &&
        `
        color: ${theme.colors[iconColor]};
    `};

    svg,
    .dxp-icon,
    .icon {
        padding: 0;
        transform: ${(props) => (props.isDown ? "rotate(180deg)" : "none")};
        transition:
            transform 0.3s,
            color 0.3s;
        ${position};

        ${({ size }) =>
            !!size &&
            css`
                line-height: 1;
                &::before {
                    font-size: ${theme.iconSize[size]} !important;
                }
            `};
    }
`;

export const UspIconWrapper = styled.div<{ size?: number; iconColor?: ColorType }>`
    line-height: 1;
    color: ${({ iconColor }) => iconColor && theme.colors[iconColor]};
    ${({ size }) =>
        size &&
        css`
            .usp-icon {
                width: ${size}px;
                height: ${size}px;
                &::before {
                    width: ${size}px;
                    height: ${size}px;
                    font-size: ${size}px;
                    top: 1px;
                    left: 0;
                }
            }
        `}
`;

export default IconWrapper;
