import styled from "styled-components";
import type {
    SpaceProps,
    TextAlignProps,
    DisplayProps,
    MaxWidthProps,
    FlexboxProps,
    LayoutProps,
    PositionProps,
} from "styled-system";
import { space, textAlign, display, maxWidth, compose, layout, flexbox, position } from "styled-system";
import { styledSystemTheme, theme } from "../theme/lexusTheme";

const styleProps = compose(space, textAlign, display, maxWidth, flexbox, layout, position);
type StyleType = SpaceProps &
    TextAlignProps &
    DisplayProps &
    MaxWidthProps &
    FlexboxProps &
    LayoutProps &
    PositionProps;

export const Container = styled.div.attrs(() => ({
    className: "container",
}))<StyleType>`
    padding: 0 ${styledSystemTheme.noContainerSpacing ? 0 : theme.spacer / 2}px;
    ${styleProps};
`;

export const ContainerFluid = styled.div.attrs(() => ({
    className: "container-fluid",
}))<StyleType>`
    padding: 0 ${styledSystemTheme.noContainerSpacing ? 0 : theme.spacer / 2}px;
    ${styleProps};
`;

/**
 * Relative ** Deprecated component **
 *  - Use the default Container component with position="relative"
 */
export const Relative = styled(Container)`
    position: relative;
`;

export default Container;
