import styled from "styled-components";
import { getBreakpoint, getDirection } from "../../../../../../common-deprecated/themes/common";
import { theme } from "../../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";
import { ContainerFluid } from "../../../../../../common-deprecated/styles/v2/lexus/globals/Container";
import * as ModelResultStyles from "../../../../model-filter/components/lexus/model-results/styles/ModelResultStyles";

export const Wrapper = styled(ContainerFluid)`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 50px;
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-auto-rows: 1fr;
    width: 100%;

    @media ${getBreakpoint("up", "md")} {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media ${getBreakpoint("up", "lg")} {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    ${ModelResultStyles.Wrapper} {
        margin: 0;
        @media ${getBreakpoint("up", "xl")} {
            &:nth-child(3n) {
                border-${getDirection("right")}: 1px solid ${theme.colors.silver};
            }
            &:nth-child(4n) {
                border-${getDirection("right")}: none;
            }
        }
    }
`;
