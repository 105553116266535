import type { ModelFilterLabelType } from "../../utils/labels";
import { modelFilterLabels } from "../../utils/labels";
import type { ModelFilterSettingsActionsType } from "../actions/ModelFilterSettingsActions";
import { MODEL_FILTER_SETTINGS_INIT } from "../actions/ModelFilterSettingsActions";
import type { NewCarFuelTypeFilterId } from "../../../../../common-deprecated/constants";
import type { ModelFilterComingSoonBlockConfigType } from "../../server/AEMModelFilterSettingsTypes";

export type ModelFilterSettingsType = {
    initialized: boolean;
    fuelTypeSortOrder: NewCarFuelTypeFilterId[];
    newCarDisableMonthlySort: boolean;
    comingSoonBlockConfigurations?: ModelFilterComingSoonBlockConfigType[];
    enableB2B: boolean;
    businessLeasingAvailabilityService: string;
    labels: Record<ModelFilterLabelType, string>;
};

export const initialStateModelFilterSettings: ModelFilterSettingsType = {
    initialized: false,
    fuelTypeSortOrder: [],
    newCarDisableMonthlySort: false,
    comingSoonBlockConfigurations: [],
    enableB2B: false,
    businessLeasingAvailabilityService: "",
    labels: { ...modelFilterLabels },
};

const ModelFilterSettingsReducer = (
    state: ModelFilterSettingsType = initialStateModelFilterSettings,
    action: ModelFilterSettingsActionsType,
): ModelFilterSettingsType => {
    switch (action.type) {
        case MODEL_FILTER_SETTINGS_INIT: {
            return { ...state, ...action.initState, initialized: true };
        }

        default:
            return state;
    }
};

export default ModelFilterSettingsReducer;
