import type { ModelResultsActionsType } from "../actions/ModelResultsActions";
import {
    FETCH_MODEL_RESULTS,
    FILTER_MODEL_RESULTS,
    SET_MODEL_RESULTS,
    SET_MODEL_RESULTS_ORDER,
    SET_UNFILTERED_MODEL_RESULTS,
} from "../actions/ModelResultsActions";
import { ModelFilterSortOrder } from "../../utils/constants/filterConstants";
import type { ModelResultType } from "../../../../../shared-logic/features/filters/utils/constants/resultConstants";

export type ModelResultsReducerType = {
    initialized: boolean;
    fetching: boolean;
    totalResultCount: number;
    sortOrder: ModelFilterSortOrder;
    results: ModelResultType[];
    unfilteredResults?: ModelResultType[]; // Only used on retailer.
};

export const initialState: ModelResultsReducerType = {
    initialized: false,
    fetching: false,
    totalResultCount: 0,
    sortOrder: ModelFilterSortOrder.ModelIndex,
    results: [],
    unfilteredResults: undefined,
};

const ModelResultsReducer = (
    state: ModelResultsReducerType = initialState,
    action: ModelResultsActionsType,
): ModelResultsReducerType => {
    switch (action.type) {
        case FETCH_MODEL_RESULTS: {
            return { ...state, fetching: true };
        }

        case SET_MODEL_RESULTS: {
            const { results, totalResultCount } = action;

            return {
                ...state,
                initialized: true,
                fetching: false,
                totalResultCount,
                results,
            };
        }

        case SET_MODEL_RESULTS_ORDER: {
            return { ...state, sortOrder: action.order };
        }

        case FILTER_MODEL_RESULTS: {
            return {
                ...state,
                fetching: true,
            };
        }

        case SET_UNFILTERED_MODEL_RESULTS: {
            return { ...state, unfilteredResults: action.unfilteredResults };
        }

        default:
            return state;
    }
};

export default ModelResultsReducer;
