import React from "react";
import * as ButtonStyles from "../../../../styles/v2/lexus/components/Button";
import * as Text from "../../../../styles/v2/lexus/utilities/Text";
import Icon from "../../../../styles/v2/lexus/globals/Icon";

type ButtonProps = {
    href?: string;
    onClick?: (event: any) => void;
    disabled?: boolean;
    iconRight?: boolean;
    iconLeft?: boolean;
    target?: string;
    rel?: string;
    children: React.ReactNode;
    fullWidth?: boolean;
};

const ButtonLink = (props: ButtonProps): JSX.Element => {
    const { href, onClick, disabled, iconLeft, iconRight, target, rel, children, fullWidth } = props;
    return (
        <ButtonStyles.ButtonLink mx={0} px={0} fullWidth={fullWidth}>
            <Text.Button
                as={href ? "a" : "button"}
                role={href ? "link" : "button"}
                type={!href ? "button" : undefined}
                href={href && !disabled ? href : undefined}
                target={target}
                rel={rel}
                onClick={onClick}
                className={`btn-text${disabled ? " disabled" : ""}${iconLeft ? " icon-left" : ""}`}
                disabled={!href && disabled}
                aria-disabled={disabled || undefined}
            >
                <span className="btn-text t-base-text l-micro-text">{children}</span>
                {(iconLeft || iconRight) && <Icon variant="externalLink" />}
            </Text.Button>
        </ButtonStyles.ButtonLink>
    );
};

export default ButtonLink;
