import type { TypedUseSelectorHook } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import type { Dispatch, ReducersMapObject, Store } from "redux";
import CommonSettingsReducer from "../../../../common-deprecated/redux/reducers/CommonSettingsReducer";
import modalReducer from "../../../../common-deprecated/redux/reducers/ModalReducer";
import utilReducer from "../../../../common-deprecated/redux/reducers/UtilReducer";
import { getInitStoreByReducers } from "../../../../common-deprecated/redux/storeHelpers";
import type { CreateLogicType } from "../../../../common-deprecated/redux/typeHelpers";
import type { ModelFilterSettingsType } from "./reducers/ModelFilterSettingsReducer";
import ModelFilterSettingsReducer from "./reducers/ModelFilterSettingsReducer";
import type { ModelFilterSettingsActionsType } from "./actions/ModelFilterSettingsActions";
import type { ModelFiltersReducerType } from "./reducers/ModelFiltersReducer";
import modelFiltersReducer from "./reducers/ModelFiltersReducer";
import type { ModelResultsReducerType } from "./reducers/ModelResultsReducer";
import modelResultsReducer from "./reducers/ModelResultsReducer";
import type { ModelFiltersActionsType } from "./actions/ModelFiltersActions";
import type { ModelResultsActionsType } from "./actions/ModelResultsActions";
import modelResultLogic from "./logic/modelResultLogic";
import modelFilterLogic from "./logic/modelfilterLogic";
import type { RetailerSettingsType } from "../../../../shared-logic/features/retailer/redux/reducers/RetailerSettingsReducer";
import RetailerSettingsReducer from "../../../../shared-logic/features/retailer/redux/reducers/RetailerSettingsReducer";
import type { RetailerSettingsActionsType } from "../../../../shared-logic/features/retailer/redux/actions/RetailerSettingsActions";
import type { CommonActionsType, CommonReducerType } from "../../../../common-deprecated/redux/commonStore";

export type ModelFilterStateType = CommonReducerType & {
    modelFilters: ModelFiltersReducerType;
    modelResults: ModelResultsReducerType;
    modelFilterSettings: ModelFilterSettingsType;
    retailerSettings: RetailerSettingsType;
};

export type ModelFilterActionsType =
    | CommonActionsType
    | ModelFiltersActionsType
    | ModelResultsActionsType
    | ModelFilterSettingsActionsType
    | RetailerSettingsActionsType;

export type ModelFilterDispatchType = Dispatch<ModelFilterActionsType>;

export type ModelFilterLogicType<ActionsType extends ModelFilterActionsType> = CreateLogicType<
    ActionsType,
    ModelFilterStateType,
    ModelFilterDispatchType
>;

export type ModelFilterStoreType = Store<ModelFilterStateType, ModelFilterActionsType>;

export const useModelFilterDispatch = useDispatch<ModelFilterDispatchType>;

export const useModelFilterSelector: TypedUseSelectorHook<ModelFilterStateType> = useSelector;

export const modelFilterReducers: ReducersMapObject<ModelFilterStateType, any> = {
    modelFilters: modelFiltersReducer,
    modelResults: modelResultsReducer,
    modal: modalReducer,
    modelFilterSettings: ModelFilterSettingsReducer,
    commonSettings: CommonSettingsReducer,
    retailerSettings: RetailerSettingsReducer,
    utils: utilReducer,
};

export const modelFilterServerStore = getInitStoreByReducers(modelFilterReducers);
export const modelFilterClientStore = getInitStoreByReducers(modelFilterReducers, [
    ...modelResultLogic,
    ...modelFilterLogic,
]);
