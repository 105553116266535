import type { PropsWithChildren, ReactNode } from "react";
import React from "react";
import * as Styles from "./styles/BadgeStyles";
import type { ColorType } from "../../../../styles/v2/lexus/theme/lexusTheme";

type BadgePropsType = {
    bgColor?: ColorType;
    color?: ColorType;
    icon?: ReactNode;
} & PropsWithChildren;

const Badge = ({ icon, children, ...props }: BadgePropsType): JSX.Element => {
    return (
        <Styles.Badge {...props}>
            {icon}
            <span>{children}</span>
        </Styles.Badge>
    );
};

export default Badge;
