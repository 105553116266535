import type { CommonSettingsType } from "../../../../common-deprecated/settings/fetchCommonSettings";
import type {
    ModelResultType,
    NewCarResultType,
} from "../../../../shared-logic/features/filters/utils/constants/resultConstants";
import {
    getCarImageFromCardb,
    isCod,
    isLexus,
    isMasterOrSlave,
} from "../../../../common-deprecated/settings/utils/commonSettingUtils";
import { AssetSizeType } from "../../../../common-deprecated/types/CommonTypes";
import type { ImageOptionsType } from "../../../../common-deprecated/settings/utils/ccisSettingUtils";
import { getCarImageVisualForModel } from "../../../../common-deprecated/settings/utils/ccisSettingUtils";
import type { RetailerSettingsType } from "../../../../shared-logic/features/retailer/redux/reducers/RetailerSettingsReducer";
import { shouldUseToyotaRetailerApheleia } from "../../../../shared-logic/features/retailer/utils/utils";
import { RetailerCarConfigTypes } from "../../../../shared-logic/features/retailer/utils/constants";
import { environmentMap } from "../../../../common-deprecated/utils/environments";
import { propagateCommonQueryParamsAsString } from "../../../../common-deprecated/utils";
import {
    CarConfigStep,
    getCarConfigStepPath,
} from "../../../../common-deprecated/features/apheleia/utils/carConfigNavigationUtils";
import Navigation, { NavStep } from "../../shared-logic/utils/Navigation";

export const getModelResultImage = (
    commonSettings: CommonSettingsType,
    model: ModelResultType,
    frame: number,
    imageOptions: ImageOptionsType,
): string => {
    const { id, assets } = model;

    const carDbImage = getCarImageFromCardb(commonSettings, assets || [], AssetSizeType.BIG, 432, 190);
    const ccisImage = getCarImageVisualForModel(commonSettings, "exterior", frame, id, imageOptions);

    return carDbImage && !commonSettings.query.forceCCIS ? carDbImage : ccisImage.src;
};

export const showComingSoon = (commonSettings: CommonSettingsType, results: ModelResultType[]): boolean => {
    const { isStandalone } = commonSettings.query;
    return !isCod(commonSettings) && !isMasterOrSlave(commonSettings) && !isStandalone && results.length > 0;
};

/**
 * Get build and buy url based on newCarResult
 * Only use this on standalone
 */
export const getBuildUrlBasedOnNewCarResult = (
    newCarResult: NewCarResultType,
    commonSettings: CommonSettingsType,
    retailerSettings: RetailerSettingsType,
): string => {
    const { environment, brand, country, language, resourcePath } = commonSettings;
    const component = shouldUseToyotaRetailerApheleia(
        newCarResult.model.code,
        retailerSettings.modelData,
        commonSettings,
    );

    if (component !== RetailerCarConfigTypes.Build) {
        const aphDomain = environmentMap.apheleia.domains[environment].replace("{brand}", brand);
        const domain = resourcePath.includes("https") ? aphDomain : ""; // http -> local hosting -> local dev
        if (component === RetailerCarConfigTypes.AphLbx) {
            const queryParams = propagateCommonQueryParamsAsString(commonSettings, {
                path: getCarConfigStepPath(CarConfigStep.Configure, newCarResult.model.id, newCarResult.id),
                useLbxJourney: true,
            });
            // LBX doesn't have a standalone grade selector, redirect to carConfig
            return `${domain}/${country}/${language}/car-config?${queryParams}`;
        } else {
            const queryParams = propagateCommonQueryParamsAsString(commonSettings, {
                modelId: newCarResult.model.id,
            });
            return `${domain}/${country}/${language}/grade-selector?${queryParams}`;
        }
    }

    // If no apheleia journey is set, redirect to build
    const step = isLexus(commonSettings) ? NavStep.Submodels : NavStep.Model;

    const navBuildPath = Navigation.build(
        commonSettings,
        step,
        {
            modelId: newCarResult.model.id,
            gradeId: "", // not used on model & submodel steps
            bodyTypeId: "",
            carId: "",
        },
        newCarResult.colourId ? `c=${newCarResult.colourId}` : undefined,
    );

    return `/${country}/${language}/build-and-buy${navBuildPath}`;
};
