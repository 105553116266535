import React from "react";
import * as Styles from "./style/ModelResultPriceStyles";
import { useModelFilterSelector } from "../../../../redux/store";
import {
    formatAEMCashPriceIsrael,
    getAemPriceConfig,
} from "../../../../../../../common-deprecated/settings/utils/financeSettingUtilsAem";
import { AemFmComponent } from "../../../../../../../common-deprecated/utils/aemFlexibilityMatrixUtils";
import type { ModelResultCashPriceType } from "./ModelResultCashPrice";

const ModelResultCashPrice = (props: ModelResultCashPriceType): JSX.Element | null => {
    const { price, modelCode = "" } = props;
    const commonSettings = useModelFilterSelector((state) => state.commonSettings);

    const priceConfig = getAemPriceConfig(commonSettings, AemFmComponent.ModelFilter, modelCode);

    if (!priceConfig.priceEnabled) return null;

    const cashFormatted = formatAEMCashPriceIsrael(
        price.discount,
        commonSettings.culture.name,
        priceConfig,
        price.licenseFee,
    );

    return (
        <Styles.PricesWrapper>
            <Styles.Cash>
                {cashFormatted.map((value, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <span key={index}>
                        <Styles.SpanText dangerouslySetInnerHTML={{ __html: value }} />
                    </span>
                ))}
            </Styles.Cash>
        </Styles.PricesWrapper>
    );
};

export default ModelResultCashPrice;
