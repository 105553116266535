import { useEffect } from "react";
import type { ModelFilterDispatchType } from "../../model-filter/redux/store";
import { useModelFilterSelector } from "../../model-filter/redux/store";
import useStatus from "../../../../common-deprecated/hooks/useStatus";
import { fetchModelResults } from "../../model-filter/redux/actions/ModelResultsActions";
import { useDispatch } from "../../../../shared-logic/redux/store";

/**
 * Used in ModelFilterResults.
 */
const useModelFilterResults = (): void => {
    const dispatch = useDispatch<ModelFilterDispatchType>();
    const commonSettings = useModelFilterSelector((state) => state.commonSettings);
    const resultsInitialized = useModelFilterSelector((state) => state.modelResults.initialized);

    useStatus(commonSettings, "Model Filter Results");

    // initial results fetch
    useEffect(() => {
        // If the component is not initialized it means we need to load initial data.
        if (!resultsInitialized) {
            dispatch(fetchModelResults(false));
        }
    }, []);
};
export default useModelFilterResults;
