import type { FC } from "react";
import React from "react";
import { IconCalculator, IconShoppingCart, IconSoldOutCart } from "../../../../styles/v2/lexus/globals/Icon";
import Badge from "../badge/Badge";
import { useCommonOptionalLabel } from "../../../../utils/commonLabels";
import * as Styles from "./styles/AvailabilityBadgesStyles";

const AvailabilityBadges: FC<{
    isSoldOut?: boolean;
    isAvailableForOnlineSales?: boolean;
    isAvailableForOnlineLeasing?: boolean;
}> = ({ isSoldOut, isAvailableForOnlineSales, isAvailableForOnlineLeasing }) => {
    const [soldOutLabel, availableForOnlineSaleLabel, availableForOnlineLeasingLabel] = useCommonOptionalLabel([
        "soldOut",
        "availableForOnlineSale",
        "availableForOnlineLeasing",
    ]);
    const showIsSoldOut = isSoldOut && soldOutLabel;
    const showIsAvailableForOnlineSales = isAvailableForOnlineSales && availableForOnlineSaleLabel;
    const showIsAvailableForOnlineLeasing = isAvailableForOnlineLeasing && availableForOnlineLeasingLabel;

    if (!showIsSoldOut && !showIsAvailableForOnlineSales && !showIsAvailableForOnlineLeasing) return null;

    return (
        <Styles.AvailabilityBadges>
            <Styles.List>
                {showIsSoldOut ? (
                    <li>
                        <Badge
                            bgColor="danger"
                            color="dangerDark"
                            icon={<IconSoldOutCart width="20" height="20" viewBox="0 -2 20 20" />}
                        >
                            {soldOutLabel}
                        </Badge>
                    </li>
                ) : (
                    <>
                        {showIsAvailableForOnlineSales && (
                            <li>
                                <Badge
                                    bgColor="successLight"
                                    color="successDark"
                                    icon={<IconShoppingCart width="20" height="20" viewBox="0 0 20 20" />}
                                >
                                    {availableForOnlineSaleLabel}
                                </Badge>
                            </li>
                        )}
                        {showIsAvailableForOnlineLeasing && (
                            <li>
                                <Badge
                                    bgColor="grey2"
                                    color="black"
                                    icon={<IconCalculator width="20" height="20" viewBox="0 0 20 20" />}
                                >
                                    {availableForOnlineLeasingLabel}
                                </Badge>
                            </li>
                        )}
                    </>
                )}
            </Styles.List>
        </Styles.AvailabilityBadges>
    );
};

export default AvailabilityBadges;
