import React from "react";
import type { LayoutProps } from "styled-system";
import { useModelFilterSelector } from "../../../redux/store";
import {
    IconArrowRightLong,
    IconArrowLeftLong,
} from "../../../../../../common-deprecated/styles/v2/lexus/globals/Icon";
import * as Text from "../../../../../../common-deprecated/styles/v2/lexus/utilities/Text";
import * as Styles from "./styles/ModelResultStyles";
import { ModelFilterLabel } from "../../../utils/labels";
import type { ModelResultType } from "../../../../../../shared-logic/features/filters/utils/constants/resultConstants";
import { trackSelectResult, trackSelectModel } from "../../../utils/tracking";
import { getModelMapValueById, isRTL } from "../../../../../../common-deprecated/settings/utils/commonSettingUtils";
import { getBuildUrlBasedOnNewCarResult, getModelResultImage } from "../../../utils/helpers";
import { ImageFluid } from "../../../../../../common-deprecated/styles/v2/lexus/globals/Image";
import { redirect } from "../../../../../../common-deprecated/utils";
import ModelResultPrice from "./price/ModelResultPrice";
import ButtonLink from "../../../../../../common-deprecated/components/aem/lexus/button/ButtonLink";
import ButtonWithIcon from "../../../../../../common-deprecated/components/aem/lexus/button/ButtonWithIcon";
import { showPrice } from "../../../../../../common-deprecated/settings/utils/financeSettingUtilsCommon";
import { getEcoBonusPromotions } from "../../../../../../common-deprecated/utils/promotionUtils";
import type { EcoTagType, NewCarResultPromotionType } from "../../../../../../common-deprecated/types/CommonTypes";
import EcoBonusPromotion from "../../../../../../common-deprecated/components/aem/lexus/EcoBonusPromotion";
import { Ellipsis } from "../../../../../../common-deprecated/styles/v2/lexus/utilities/Ellipsis";
import Spacer from "../../../../../../common-deprecated/styles/v2/lexus/utilities/Spacer";
import AvailabilityBadges from "../../../../../../common-deprecated/components/aem/lexus/availability-badges/AvailabilityBadges";
import EcoTags from "./eco-tags/EcoTags";

type PropsType = {
    model: ModelResultType;
    hasRightBorder?: boolean;
};

const ModelResult = ({ model, hasRightBorder }: PropsType & LayoutProps): JSX.Element => {
    const commonSettings = useModelFilterSelector((state) => state.commonSettings);
    const retailerSettings = useModelFilterSelector((state) => state.retailerSettings);
    const { isStandalone } = commonSettings;
    const { id, name, cars, cheapestCar = cars[0], promotions } = model;
    const { links } = getModelMapValueById(commonSettings, id) || {};

    const ecoTags = new Set(
        cars.filter((car) => Boolean(car.grade.ecoTag)).map((car) => car.grade.ecoTag as EcoTagType),
    ); // We check all the cars of the model and filter out the distinct ecoTags.

    const shouldShowPrice = showPrice(commonSettings);

    const carImage = getModelResultImage(commonSettings, model, isRTL(commonSettings) ? 31 : 4, {
        type: "png",
        width: 390,
        height: 260,
        padding: "0,0,-50,30",
        scaleMode: 3,
    });

    // URL for standalone link to build and buy
    const standaloneBuildUrl = getBuildUrlBasedOnNewCarResult(cheapestCar, commonSettings, retailerSettings);
    const modelPageUrl = (isStandalone ? standaloneBuildUrl : links?.modelPage) || "";
    const buildPageUrl = (isStandalone ? standaloneBuildUrl : links?.buildPage) || "";

    // If the model has one/more then one grade that is availableForOnlineSales this needs to be shown.
    const modelIsAvailableForOnlineSale = cars.filter((car) => car.grade.availableForOnlineSales).length > 0;
    const modelIsAvailableForOnlineLeasing = cars.filter((car) => car.grade.availableForOnlineLeasing).length > 0;
    // if all cars are sold out, the model is sold out
    const modelIsSoldOut = cars.filter((car) => !car.soldOut).length === 0;

    const ecoBonus = promotions ? getEcoBonusPromotions<NewCarResultPromotionType>(promotions, promotions) : [];

    return (
        <Styles.Wrapper hasRightBorder={hasRightBorder}>
            <Styles.ImageWrapper
                href={modelPageUrl}
                onClick={(event) => {
                    event.preventDefault();
                    redirect(modelPageUrl);
                    trackSelectModel(name);
                }}
            >
                <Styles.Heading>
                    <Styles.Title>{name}</Styles.Title>
                    <EcoTags ecoTags={Array.from(ecoTags)} />
                </Styles.Heading>
                <ImageFluid src={carImage} alt={`${name} ${cheapestCar.grade.name} ${cheapestCar.bodyType.name}`} />
            </Styles.ImageWrapper>

            <Spacer mb={2} px="20px">
                <AvailabilityBadges
                    isSoldOut={modelIsSoldOut}
                    isAvailableForOnlineSales={modelIsAvailableForOnlineSale}
                    isAvailableForOnlineLeasing={modelIsAvailableForOnlineLeasing}
                />
                {shouldShowPrice && <ModelResultPrice model={model} commonSettings={commonSettings} />}
                <EcoBonusPromotion ecoBonus={ecoBonus} />
            </Spacer>

            <Styles.Actions>
                <ButtonLink
                    onClick={(event) => {
                        event.preventDefault();
                        redirect(modelPageUrl);
                        trackSelectModel(name);
                    }}
                    href={modelPageUrl}
                    data-url={modelPageUrl}
                    aria-label={name}
                    data-testid={`viewmodel-${id}`}
                >
                    <ModelFilterLabel label="viewModel" />
                </ButtonLink>
                <ButtonWithIcon
                    onClick={(event) => {
                        event.preventDefault();
                        redirect(buildPageUrl);
                        trackSelectResult(name);
                    }}
                    href={buildPageUrl}
                >
                    <Text.IconButton>
                        <Ellipsis maxWidth="100%">
                            <ModelFilterLabel label="goToBuild" />
                        </Ellipsis>
                    </Text.IconButton>
                    {isRTL(commonSettings) ? <IconArrowLeftLong /> : <IconArrowRightLong />}
                </ButtonWithIcon>
            </Styles.Actions>
        </Styles.Wrapper>
    );
};

export default ModelResult;
