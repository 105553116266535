import type { SyntheticEvent } from "react";
import React, { Fragment } from "react";
import * as Styles from "./style/ModelResultPriceStyles";
import { CommonMonthlyPriceLabelKey } from "../../../../../../../common-deprecated/priceUtils";
import Icon from "../../../../../../../common-deprecated/styles/v2/lexus/globals/Icon";
import type { CommonFinanceInfoType, PriceType } from "../../../../../../../common-deprecated/types/CommonTypes";
import { showModal } from "../../../../../../../common-deprecated/redux/actions/ModalActions";
import type { ModelFilterDispatchType } from "../../../../redux/store";
import { useModelFilterSelector } from "../../../../redux/store";
import {
    getAemMonthlyRateFormat,
    showAemMonthlyRates,
} from "../../../../../../../common-deprecated/settings/utils/financeSettingUtilsAem";
import { MODAL_FINANCE_DISCLAIMER } from "../../../../../../../common-deprecated/utils/modalConstants";
import { AemFmComponent } from "../../../../../../../common-deprecated/utils/aemFlexibilityMatrixUtils";
import { formatMonthlyFinancePrice } from "../../../../../../shared-logic/utils/priceUtils";
import { useDispatch } from "../../../../../../../shared-logic/redux/store";

type ModelResultMonthlyPriceType = {
    price: PriceType;
    financeInfo: CommonFinanceInfoType | null;
    modelCode?: string;
};

const ModelResultMonthlyPrice = (props: ModelResultMonthlyPriceType): JSX.Element | null => {
    const { price, financeInfo, modelCode = "" } = props;
    const dispatch = useDispatch<ModelFilterDispatchType>();
    const commonSettings = useModelFilterSelector((state) => state.commonSettings);
    const { hideFinanceTooltip } = commonSettings;

    const monthlyEnabled = showAemMonthlyRates(commonSettings, AemFmComponent.ModelFilter, "new", modelCode);
    if (!monthlyEnabled) return null;

    const monthlyPriceFormat = getAemMonthlyRateFormat(commonSettings, AemFmComponent.ModelFilter, "new", modelCode);
    const financeLabels = financeInfo
        ? formatMonthlyFinancePrice(price, financeInfo, commonSettings, monthlyPriceFormat).labels
        : [];
    const getDisclaimerFn =
        (info: CommonFinanceInfoType) =>
        (event: SyntheticEvent): void => {
            event.preventDefault();
            event.stopPropagation();
            if (!info.disclaimer) return;
            dispatch(
                showModal({
                    type: MODAL_FINANCE_DISCLAIMER,
                    disclaimer: info.disclaimer,
                    productName: info.productName || "",
                }),
            );
        };

    return (
        <Styles.MonthlyWrapper>
            {financeLabels.map(({ key, value }) => (
                <Fragment key={key}>
                    {key === CommonMonthlyPriceLabelKey.Price ? (
                        <Styles.Monthly onClick={financeInfo?.disclaimer ? getDisclaimerFn(financeInfo) : undefined}>
                            <span dangerouslySetInnerHTML={{ __html: value }} />
                            &nbsp;
                            {!hideFinanceTooltip && !!financeInfo?.disclaimer && (
                                <Styles.MonthlyPriceDisclaimer>
                                    <Styles.DisclaimerIcon>
                                        <Icon variant="info" />
                                    </Styles.DisclaimerIcon>
                                </Styles.MonthlyPriceDisclaimer>
                            )}
                        </Styles.Monthly>
                    ) : (
                        <Styles.LegalText dangerouslySetInnerHTML={{ __html: value }} />
                    )}
                </Fragment>
            ))}
        </Styles.MonthlyWrapper>
    );
};

export default ModelResultMonthlyPrice;
