import styled from "styled-components";
import type { SpaceProps } from "styled-system";
import { space } from "styled-system";
import { theme } from "../../../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";
import * as Button from "../../../../../../../common-deprecated/styles/v2/lexus/components/Button";
import { getBreakpoint, getDirection } from "../../../../../../../common-deprecated/themes/common";
import { ButtonLink } from "../../../../../../../common-deprecated/styles/v2/lexus/components/Button";
import { AvailabilityBadges } from "../../../../../../../common-deprecated/components/aem/lexus/availability-badges/styles/AvailabilityBadgesStyles";

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-auto-rows: 1fr;
    width: 100%;

    @media ${getBreakpoint("up", "md")} {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media ${getBreakpoint("up", "lg")} {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
`;

export const Container = styled.div<SpaceProps>`
    ${space}

    @media ${getBreakpoint("down", "md")} {
        padding-top: ${theme.space[3]}px;
        border-top: 1px solid ${theme.colors.silver};
    }
`;

export const LegalBannerContainer = styled(Container)`
    max-width: 1440px;
    margin: 0 auto;
    padding: 40px 16px;
    @media ${getBreakpoint("up", "lg")} {
        padding: 80px 40px;
    }
`;

export const Header = styled.div`
    padding-bottom: ${theme.space[5]}px;
    text-align: center;
    @media ${getBreakpoint("up", "md")} {
        border-bottom: 1px solid ${theme.colors.silver};
    }
`;

export const HeaderWrapper = styled.div`
    margin-bottom: ${theme.space[1]}px;
`;

export const Main = styled.div`
    display: flex;
    flex-wrap: wrap;
    @media ${getBreakpoint("up", "lg")} {
        flex-wrap: nowrap;
    }
`;

export const Wrapper = styled.div<{ hasRightBorder?: boolean }>`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    padding: ${theme.space[5]}px ${theme.space[3]}px;
    border-bottom: 1px solid ${theme.colors.silver};
    border-${getDirection("right")}: 1px solid ${theme.colors.silver};

    @media ${getBreakpoint("up", "md")} {
        border-bottom: 1px solid ${theme.colors.silver};
        &:nth-child(2n) {
            border-${getDirection("right")}: none;
        }
    }

    @media ${getBreakpoint("up", "lg")} {
        padding: ${theme.space[6]}px 20px;
        border-bottom: 1px solid ${theme.colors.silver};
        border-${getDirection("right")}: 1px solid ${theme.colors.silver};
        &:nth-child(1n) {
            border-${getDirection("right")}: 1px solid ${theme.colors.silver};
        }
        &:nth-child(3n) {
            border-${getDirection("right")}: none;
        }
    }

    ${AvailabilityBadges} {
        margin-bottom: ${theme.space[2]}px;
    }
`;

export const ImageWrapper = styled.a`
    width: 100%;
    margin-bottom: ${theme.space[5]}px;
`;

export const Heading = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-inline: 2rem;

    @supports not (padding-inline: 0) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
`;

export const Title = styled.div.attrs(() => ({
    className: "l-beta-text",
}))`
    margin-bottom: 0;
    font-family: ${theme.fonts.regular};
    font-size: 1.8rem;
    letter-spacing: 1.3%;
    line-height: 2.4rem;
    text-transform: none;
    color: ${theme.colors.midnight};

    @media ${getBreakpoint("up", "lg")} {
        font-size: 4.4rem;
        line-height: 4.6rem;
    }
`;

export const Subtitle = styled.div.attrs(() => ({
    className: "l-micro-text",
}))`
    align-self: center;
    margin-${getDirection("left")}: ${theme.space[3]}px;
    font-size: 1.8rem;
    line-height: 2.4rem;
    text-transform: uppercase;
    color: ${theme.colors.midnight};

    @media ${getBreakpoint("up", "lg")} {
        font-size: 1.4rem;
        line-height: 2.4rem;
    }
    color: ${theme.colors.midnight};

`;

export const GradeLink = styled(Button.Link)`
    font-family: ${theme.fonts.bold};
    font-size: 1.4rem;
    line-height: 2rem;
    color: ${theme.colors.azure};
    &:hover {
        color: ${theme.colors.azureHover};
    }
`;

export const Actions = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: auto;
    padding-inline: 2rem;

    ${ButtonLink} {
        margin-right: 0 !important;
        .l-icon::before {
            margin-top: -2px;
        }
    }

    @supports not (padding-inline: 0) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
`;
