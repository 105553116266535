import { createLogic } from "redux-logic";
import type { ModelFilterLogicType } from "../store";
import type { FetchModelResultsType, FilterModelResultsType } from "../actions/ModelResultsActions";
import {
    FETCH_MODEL_RESULTS,
    FILTER_MODEL_RESULTS,
    filterModelResults,
    setModelResults,
    SET_MODEL_RESULTS_ORDER,
} from "../actions/ModelResultsActions";
import Debug from "../../../../../common-deprecated/Debug";
import type { ModelFiltersActionsType } from "../actions/ModelFiltersActions";
import {
    MODEL_FILTER_ACTIONS,
    updateMultiFilterCounts,
    MODEL_FILTER_MAIN_FILTER_ACTIONS,
} from "../actions/ModelFiltersActions";
import { fetchModelResults } from "../../utils/filters";
import { replaceQueryParams } from "../../../../../common-deprecated/History";
import { COMPONENT_NAME_MODEL_FILTER } from "../../../../../common-deprecated/AnalyticsConstants";
import { trackEvent } from "../../../../../common-deprecated/utils/tracking";
import { ModelFilterQueryParam, modelFilterValuesToParams } from "../../utils/params";
import { setSelectedFilters } from "../../utils/localStorage";

// Fetch results logic. Triggers when additional results need to be fetched.
// Initial SSR already contains model filter results, see OR-5232
const fetchResultLogic = (createLogic as ModelFilterLogicType<FetchModelResultsType>)({
    type: FETCH_MODEL_RESULTS,
    async process({ getState }, dispatch, done) {
        const { commonSettings, modelFilters, modelResults } = getState();
        const { sortOrder } = modelResults;

        try {
            const result = await fetchModelResults(commonSettings, modelFilters, sortOrder);

            if (result !== null) {
                const { totalResultCount, results, aggregations } = result;
                dispatch(setModelResults(results, totalResultCount));
                dispatch(updateMultiFilterCounts(aggregations));
            }
        } catch (e) {
            Debug.error("Failed to retrieve results", e);
        }

        done();
    },
});

// Filter results logic, triggers when a filter is updated or added.
const filterResultsLogic = (createLogic as ModelFilterLogicType<FilterModelResultsType>)({
    type: FILTER_MODEL_RESULTS,
    // Debounce Added so tracking and logic won't get triggered multiple times when clearing all Filters.
    debounce: 10,
    async process({ getState, action }, dispatch, done) {
        const { commonSettings, modelFilters, modelResults } = getState();
        const { sortOrder } = modelResults;

        const { queryString, localStorage } = modelFilterValuesToParams(modelFilters);

        // We base the tracking values on the original params object so avoid empty fields or sortorder
        const trackingKeys = Object.keys(queryString).join("|");
        const trackingValues = Object.values(queryString).join("|");

        // Add filter query params to state. Add null entries for the inactive filters to make sure they are not in the URL.
        Object.values(ModelFilterQueryParam).forEach((queryParam) => {
            // Casting it as string | null since this is used in the replaceQueryParams (and only there) to filter out parameters.
            // It would be to much overkill to cater for this particular use case
            if (!queryString[queryParam]) (queryString[queryParam] as string | null) = null;
        });
        queryString.sortOrder = sortOrder;
        // Update the querystring parameter
        replaceQueryParams(queryString);
        // Update the local storage
        setSelectedFilters({ country: commonSettings.country, language: commonSettings.language }, localStorage);

        try {
            const result = await fetchModelResults(commonSettings, modelFilters, sortOrder);
            if (result === null) return done();

            const { results, totalResultCount, aggregations } = result;

            if (!action.noTracking) {
                const variant = action.causedByMainFilter ? "standard" : "advanced";
                trackEvent(
                    {
                        name: "searchevent",
                        component: COMPONENT_NAME_MODEL_FILTER,
                        group: "new-cars",
                        action: "search",
                        label: `refine-search/${variant}`,
                    },
                    {
                        workflow: { name: "grade-search", conversion: 1, step: "refine-search", variant },
                        search: {
                            type: "grade-search",
                            query: trackingKeys,
                            results: totalResultCount > 0 ? 1 : 0,
                            filters: trackingValues,
                        },
                    },
                );
            }
            dispatch(setModelResults(results, totalResultCount));
            dispatch(updateMultiFilterCounts(aggregations));
        } catch (e) {
            Debug.error("Failed to retrieve results", e);
        }

        done();
    },
});

const refetchResultLogic = (createLogic as ModelFilterLogicType<ModelFiltersActionsType>)({
    type: [...MODEL_FILTER_ACTIONS, SET_MODEL_RESULTS_ORDER] as any,
    process({ action }, dispatch, done) {
        const isMainFilterAction = MODEL_FILTER_MAIN_FILTER_ACTIONS.includes(action.type as any);

        dispatch(filterModelResults(false, isMainFilterAction));

        done();
    },
});

const modelResultLogic = [fetchResultLogic, refetchResultLogic, filterResultsLogic];
export default modelResultLogic;
