import React, { useRef, useState } from "react";
import { showPrice } from "../../../settings/utils/financeSettingUtilsCommon";
import { useCommonSelector } from "../../../redux/commonStore";
import type { NewCarResultPromotionType, PricePromotionType } from "../../../types/CommonTypes";
import PopoverTooltip from "../../popover/aem/PopoverTooltip";
import { CommonLabel, getCommonOptionalLabel } from "../../../utils/commonLabels";
import * as Button from "../../../styles/v2/lexus/components/Button";

type EcoBonusPromotionTypes = {
    ecoBonus: (PricePromotionType | NewCarResultPromotionType)[];
};

const EcoBonusPromotion = ({ ecoBonus }: EcoBonusPromotionTypes): null | JSX.Element => {
    const commonSettings = useCommonSelector((state) => state.commonSettings);

    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const iconRef = useRef<HTMLButtonElement>(null);
    const ecoBonusTooltip = getCommonOptionalLabel({ commonSettings }, "ecoBonusTooltip");

    if (!showPrice(commonSettings) || ecoBonus.length <= 0 || !ecoBonusTooltip) return null;

    const onClose = (): void => setShowTooltip(false);

    return (
        <>
            <Button.Link
                ref={iconRef}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                <CommonLabel label="ecoBonusLabel" />
            </Button.Link>
            <PopoverTooltip
                hide={!showTooltip}
                onClose={onClose}
                iconRef={iconRef}
                content={ecoBonusTooltip}
                useHTML
                openedOnHover
            />
        </>
    );
};

export default EcoBonusPromotion;
