import styled from "styled-components";
import type {
    SpaceProps,
    FlexboxProps,
    LayoutProps,
    TypographyProps,
    PositionProps,
    DisplayProps,
} from "styled-system";
import { space, flexbox, compose, layout, typography, position, display } from "styled-system";
import { theme } from "../theme/lexusTheme";

const Flex = styled.div<PositionProps & FlexboxProps>`
    display: flex;
    ${position};
    ${flexbox};
`;

export const Row = styled(Flex)<SpaceProps & LayoutProps & FlexboxProps>`
    flex: 1 1 auto;
    flex-wrap: wrap;
    margin: 0 -${theme.spacer / 2}px;
    ${space};
    ${layout};
    ${flexbox};
`;

type StyledSystemType = LayoutProps & SpaceProps & TypographyProps & FlexboxProps & PositionProps & DisplayProps;

const styleProps = compose(layout, space, typography, flexbox, position, display);
export const Col = styled.div<StyledSystemType>`
    padding: 0 ${theme.spacer / 2}px;
    ${styleProps};
`;

export default Flex;
